<mat-label class="ml-3 pl-1">{{ label }}</mat-label>
<mat-form-field class="mt-2" appearance="outline">
  <input
    matInput
    autocomplete="off"
    [formControl]="newDID"
    type="text"
    [placeholder]="placeholder"
    data-qa-id="did-input"
    [matAutocomplete]="auto"
    maxlength="256" />
  <button
    *ngIf="showAddButton"
    matTooltip="Add DID"
    matSuffix
    mat-icon-button
    aria-label="Add"
    type="submit"
    data-qa-id="add-did"
    (click)="addDid($event)"
    [disabled]="newDID.invalid">
    <mat-icon>add</mat-icon>
  </button>
  <mat-autocomplete #auto="matAutocomplete">
    <mat-option *ngFor="let option of didBook$ | async" [value]="option.did">
      {{ option.label }}
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="newDID?.hasError('required')"> DID is <strong>required</strong> </mat-error>
  <mat-error *ngIf="newDID?.hasError('invalidDid')"> DID format is invalid</mat-error>
  <mat-error *ngIf="newDID.hasError('exist')"> This DID already exist on the list</mat-error>
</mat-form-field>
<div class="col-12" *ngIf="isNotKnownDid && newDID.valid && newDID.value">
  <div>
    Do you want to add this DID to your DID Book?
    <button mat-stroked-button class="btn-small ml-2" data-qa-id="add" (click)="approveHandler()">Yes</button>
  </div>
</div>
