<h4 mat-dialog-title class="mb-0 d-flex justify-content-between align-items-center">
  Select Asset
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</h4>

<div mat-dialog-content>
  <ng-container *ngIf="dataSource$ | async as dataSource; else noRecords">
    <table mat-table matSort class="mt-2 mb-2" [dataSource]="dataSource">
      <!-- Logo -->
      <ng-container matColumnDef="logo">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" data-label="">
          <div class="d-flex justify-content-center justify-content-md-start">
            <img class="img-logo-list" src="../assets/img/assets-icons/solar-energy.svg" />
          </div>
        </td>
      </ng-container>

      <!-- Name -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Asset Name</th>
        <td mat-cell *matCellDef="let element" data-label="Name">
          <div class="d-flex flex-row align-items-center justify-content-between justify-content-lg-start">
            <div class="text-blur">Asset Name</div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>
          <ng-container>Asset DID</ng-container>
        </th>
        <td mat-cell *matCellDef="let element" data-label="Asset DID">
          {{ element.id | didFormatMinifier }}
        </td>
      </ng-container>

      <!-- Action -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="text-right"></th>
        <td mat-cell *matCellDef="let element">
          <div class="d-flex justify-content-end">
            <button mat-icon-button aria-label="Use" [mat-dialog-close]="element">
              <mat-icon *ngIf="element['isSelected']" class="btn-color-primary"> check_circle</mat-icon>
              <mat-icon *ngIf="!element['isSelected']" class="btn-color-primary"> circle</mat-icon>
            </button>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr class="cursor-pointer" mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </ng-container>
  <ng-template #noRecords>
    <app-no-records></app-no-records>
  </ng-template>
</div>
