<ng-container *ngFor="let precondition of list; let id = index">
  <!-- Role is Approved but NOT SYNCED -->
  <div
    *ngIf="isApproved(precondition.status)"
    class="card card-success d-flex flex-row justify-content-start align-items-center shadow-none d-flex pt-3 pb-2 mb-4">
    <mat-icon>check_circle</mat-icon>
    <span class="pb-2 flex-grow-1">{{ precondition.conditionNamespace }}</span>
    <mat-icon matTooltip="Please publish this role.">info</mat-icon>
    <mat-icon
      *ngIf="precondition.issuedToken"
      [attr.data-qa-id]="'publish-' + id"
      class="d-flex sync-icons cursor-pointer"
      matTooltip="Publish credential to DID document"
      svgIcon="sync-did-icon"
      (click)="publishToDIDDoc(precondition)"></mat-icon>
  </div>
  <!-- Request is Pending or No Request at all -->
  <div
    *ngIf="isPending(precondition.status)"
    class="card card-warning d-flex flex-row justify-content-start align-items-center shadow-none d-flex pt-3 pb-2 mb-4">
    <mat-icon>pending_actions</mat-icon>
    <span class="pb-2 flex-grow-1">{{ precondition.conditionNamespace }}</span>
    <mat-icon matTooltip="You don't have this role yet.">info</mat-icon>
  </div>
</ng-container>
