<div class="table-container">
  <table mat-table [dataSource]="list">
    <ng-container matColumnDef="label">
      <th mat-header-cell *matHeaderCellDef>Label</th>
      <td mat-cell *matCellDef="let element">
        <ng-container>{{ element.label }}</ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="did">
      <th mat-header-cell *matHeaderCellDef>DID</th>
      <td
        mat-cell
        *matCellDef="let element"
        [appMinifiedDidViewer]="element?.did"
        matTooltip="{{ element?.did }}"
        matTooltipClass="tooltip-full-width">
        <ng-container>{{ element.did | didFormatMinifier }}</ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element; let i = index">
        <div class="d-flex justify-content-end">
          <button
            mat-icon-button
            matSuffix
            class="mat-primary icon-btn-small color-error"
            matTooltip="Remove"
            [attr.data-qa-id]="'remove-' + i"
            (click)="remove(element.id)">
            <mat-icon>delete</mat-icon>
          </button>
          <button
            mat-icon-button
            matSuffix
            class="mat-primary icon-btn-small color-error"
            matTooltip="Show QR Code"
            [attr.data-qa-id]="'qr-code-' + i"
            appQrCode
            header="Contact Data QR Code"
            [data]="qrCodeData(element)">
            <mat-icon class="ml-2 default-color" svgIcon="generate-qr-icon"></mat-icon>
          </button>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
