<app-menu-notification-trigger [matMenuTriggerFor]="menu" [items]="totalCount" icon="fas fa-tasks">
</app-menu-notification-trigger>

<mat-menu #menu="matMenu" xPosition="before">
  <app-notification-container>
    <app-notification-header title="Tasks" [showClear]="false"></app-notification-header>
    <a [routerLink]="['/assets']" routerLinkActive="active" [queryParams]="{ notif: 'assetsOfferedToMe' }">
      <div class="list-group-item list-group-item-action border-0">
        <div class="media d-flex align-items-center cursor-pointer">
          <div class="align-self-start mr-2">
            <mat-icon svgIcon="new-claim-icon"></mat-icon>
          </div>
          <div class="media-body d-flex flex-row justify-content-between align-items-center">
            <p class="m-0 pr-2 default-color">Accept the transfer of the Asset</p>
            <span *ngIf="assetsOfferedToMeCount" class="badge badge-danger ml-2" data-qa-id="badge-accept-transfer">
              {{ assetsOfferedToMeCount }}
            </span>
          </div>
        </div>
      </div>
    </a>
    <a routerLink="/enrolment" routerLinkActive="active">
      <div class="list-group-item list-group-item-action border-0">
        <div class="media d-flex align-items-center cursor-pointer">
          <div class="align-self-start mr-2">
            <mat-icon svgIcon="approved-claim-icon"></mat-icon>
          </div>
          <div class="media-body d-flex flex-row justify-content-between align-items-center">
            <p class="m-0 pr-2 default-color">For approval</p>
            <span *ngIf="pendingApprovalCount" class="badge badge-danger ml-2" data-qa-id="badge-for-approval">
              {{ pendingApprovalCount }}
            </span>
          </div>
        </div>
      </div>
    </a>
    <a [routerLink]="['/enrolment']" routerLinkActive="active" [queryParams]="{ notif: 'myEnrolments' }">
      <div class="list-group-item list-group-item-action border-0">
        <div class="media d-flex align-items-center cursor-pointer">
          <div class="align-self-start mr-2">
            <mat-icon>timelapse</mat-icon>
          </div>
          <div class="media-body d-flex flex-row justify-content-between align-items-center">
            <p class="m-0 pr-2 default-color">Publish</p>
            <span *ngIf="pendingAssetSyncCount" class="badge badge-danger ml-2" data-qa-id="badge-pending-sync">
              {{ pendingAssetSyncCount }}
            </span>
          </div>
        </div>
      </div>
    </a>
  </app-notification-container>
</mat-menu>
