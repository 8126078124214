<h4 mat-dialog-title class="mb-0 d-flex justify-content-between align-items-center">
  Set Azure URL
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</h4>

<div mat-dialog-content>
  <div class="mb-2 mt-3">
    <div class="row">
      <div class="col-lg-12">
        <mat-form-field class="mt-2 multi-errors" appearance="outline">
          <input
            matInput
            autocomplete="off"
            [formControl]="form"
            type="text"
            placeholder="Azure Url"
            required
            data-qa-id="azure-url" />
          <mat-error *ngIf="form.hasError('required')">Azure Url is <strong>required</strong> </mat-error>
          <mat-error *ngIf="form.hasError('invalidUrl')">Azure Url is <strong>invalid</strong> </mat-error>
        </mat-form-field>

        <div class="d-flex justify-content-end mb-3">
          <button mat-stroked-button class="btn btn-small mr-2" (click)="cancel()">Cancel</button>
          <button mat-raised-button class="btn btn-small btn-primary" [disabled]="form.invalid" (click)="save()">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
