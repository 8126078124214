<h4 mat-dialog-title class="mb-0 d-flex justify-content-between align-items-center">
  View DID
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</h4>

<div mat-dialog-content>
  <div class="mt-3">
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-default text-center mb-3 p-3 shadow-none">
          <div class="label default-color opacity-50">{{ data?.did }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
