<h4 mat-dialog-title class="mb-0 d-flex justify-content-between align-items-center">
  Update Identity
  <button mat-icon-button [mat-dialog-close]="true">
    <mat-icon>close</mat-icon>
  </button>
</h4>

<div mat-dialog-content>
  <div class="mb-2 mt-4 mx-4 mx-lg-4">
    <form role="form" [formGroup]="profileForm">
      <div class="row">
        <div class="col-lg-12">
          <mat-label class="ml-3 pl-1">Name</mat-label>
          <mat-form-field class="mt-2" appearance="outline">
            <input
              matInput
              formControlName="name"
              data-qa-id="dialog-input-name"
              placeholder="Name"
              maxlength="256"
              required
              autocomplete="off" />
            <mat-error *ngIf="profileForm.get('name').hasError('required')">
              Name is
              <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
</div>
<div mat-dialog-actions class="d-flex justify-content-center">
  <button
    mat-raised-button
    class="btn btn-primary w-100"
    type="submit"
    data-qa-id="submit"
    [disabled]="disableSubmit()"
    (click)="save()">
    Update
  </button>
</div>
