<div
  class="d-flex align-items-center justify-content-center did-wrapper mx-2 px-3 mb-2"
  appCopyToClipboard
  [copyClipboard]="did"
  message="User Did">
  <div class="f-14" data-qa-id="menu-did">{{ did | didFormatMinifier }}</div>
  <mat-icon class="ml-2 default-color">content_copy</mat-icon>
</div>
<div
  class="d-flex align-items-center justify-content-center color-default cursor-pointer mx-2 px-3 mb-2"
  appQrCode
  [data]="qrCodeData">
  User Data QR Code
  <mat-icon class="ml-2 default-color" svgIcon="generate-qr-icon"></mat-icon>
</div>
