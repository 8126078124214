<mat-label class="ml-3 pl-1">Expiration Date</mat-label>
<app-expiration-info
  [defaultValidityPeriod]="defaultValidityPeriod"
  [expirationTime]="expirationTimeShift"
  [removeExpDate]="hideRemoveButton">
</app-expiration-info>
<mat-form-field class="mt-2" appearance="outline">
  <input
    [matDatepicker]="picker"
    [min]="minDate"
    data-qa-id="expiration-date-input"
    [formControl]="expirationDate"
    matInput
    placeholder="Select a New Expiration Date"
    autocomplete="off" />
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-icon
    matSuffix
    *ngIf="showRestoreButton"
    data-qa-id="reset-date"
    class="close-icon"
    (click)="restoreDefaultHandler()"
    >close</mat-icon
  >
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
<div class="d-flex justify-content-end">
  <button
    *ngIf="showRemoveButton()"
    mat-stroked-button
    class="btn btn-small mr-2 back"
    data-qa-id="remove-expiration-date"
    (click)="removeHandler()">
    Remove expiration date
  </button>
  <button
    *ngIf="showRestoreButton()"
    mat-stroked-button
    class="btn btn-small mr-2 back"
    data-qa-id="restore-expiration-date"
    (click)="restoreDefaultHandler()">
    Restore default expiration date
  </button>
  <br />
  <br />
</div>
