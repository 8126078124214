<h4 mat-dialog-title class="mb-0 d-flex justify-content-between align-items-center">
  {{ viewType === ViewType.UPDATE ? 'Update' : 'Create' }} Organization
  <button mat-icon-button (click)="closeDialog()">
    <mat-icon>close</mat-icon>
  </button>
</h4>

<div mat-dialog-content>
  <div class="mb-2 mt-3">
    <div class="row">
      <mat-vertical-stepper class="w-100" [linear]="true" #stepper>
        <ng-template matStepperIcon="edit">
          <mat-icon>check</mat-icon>
        </ng-template>
        <mat-step [completed]="false">
          <form [formGroup]="orgForm">
            <ng-template matStepLabel> {{ viewType === ViewType.UPDATE ? 'Update' : 'New' }} Organization</ng-template>
            <div class="card card-default d-flex shadow-none d-flex pt-3 mb-3">
              <div class="row mt-2">
                <!-- <div class="col-lg-12">
                <div class="d-flex justify-content-start">
                  <div class="image-wrapper w-50 d-flex align-items-center justify-content-center mb-3">
                    <span class="opacity-50">Image Preview</span>
                  </div>
                </div>
              </div> -->
                <div class="col-lg-12" formGroupName="data">
                  <mat-label class="ml-3 pl-1"> Organization Image URL (Optional)</mat-label>
                  <mat-form-field class="mt-2" appearance="outline">
                    <input
                      matInput
                      autocomplete="off"
                      formControlName="logoUrl"
                      type="text"
                      maxlength="500"
                      pattern="https?://.*"
                      placeholder="https://www.example.com/images/logo.png" />
                    <mat-error>URL format is incorrect</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="card card-default d-flex shadow-none d-flex pt-3 mb-3">
              <div class="row">
                <div class="col-lg-12" *ngIf="viewType !== ViewType.UPDATE">
                  <mat-label class="ml-3 pl-1"> Organization Namespace</mat-label>
                  <mat-form-field class="mt-2 multi-errors" appearance="outline">
                    <input
                      matInput
                      autocomplete="off"
                      formControlName="orgName"
                      type="text"
                      placeholder="Organization Namespace"
                      required
                      (input)="parseValue(orgForm, orgForm.get('orgName').value)"
                      appBlockPaste
                      minlength="3"
                      maxlength="256" />
                    <mat-error *ngIf="formHasError('orgName', 'required')">
                      Organization Namespace is
                      <strong>required</strong>
                    </mat-error>
                    <mat-error *ngIf="formHasError('orgName', 'minlength')">
                      Organization Namespace need to have at least 3 characters.
                    </mat-error>

                    <mat-error *ngIf="formHasError('orgName', 'isAlphaNumericInvalid')">
                      Organization Namespace can contain only alphanumeric characters.
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="col-lg-12" *ngIf="viewType === ViewType.UPDATE">
                  <mat-label class="ml-3 pl-1"> Organization Namespace</mat-label>
                  <div
                    class="mt-2 card card-result d-flex flex-row justify-content-start align-items-center shadow-none d-flex pt-3 pb-2 mb-3">
                    <div class="pb-2">{{ origData?.namespace }}</div>
                  </div>
                </div>

                <div
                  *ngIf="orgForm.get('orgName').value && viewType !== ViewType.UPDATE"
                  class="mx-3 card card-result d-flex flex-row justify-content-start align-items-center shadow-none d-flex pt-3 pb-2 mb-3">
                  <span class="pb-2">
                    {{ orgForm.get('orgName').value + '.' + orgForm?.value?.namespace }}
                  </span>
                  <!-- <mat-icon>check</mat-icon> -->
                </div>
                <ng-container formGroupName="data">
                  <div class="col-lg-12">
                    <mat-label class="ml-3 pl-1">Organization Name</mat-label>
                    <mat-form-field class="mt-2" appearance="outline">
                      <input
                        matInput
                        autocomplete="off"
                        formControlName="organizationName"
                        type="text"
                        placeholder="Organization Name"
                        minlength="3"
                        maxlength="256" />
                      <mat-error *ngIf="formDataHasError('organizationName', 'required')">
                        Organization Name is
                        <strong>required</strong>
                      </mat-error>
                      <mat-error *ngIf="formDataHasError('organizationName', 'minlength')">
                        Organization Name need to have at least 3 characters.
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-lg-12">
                    <mat-label class="ml-3 pl-1"> Website URL (Optional)</mat-label>
                    <mat-form-field class="mt-2" appearance="outline">
                      <input
                        matInput
                        autocomplete="off"
                        formControlName="websiteUrl"
                        type="text"
                        pattern="https?://.*"
                        placeholder="http://www.example.com"
                        maxlength="500" />
                      <mat-error>URL format is incorrect</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-lg-12">
                    <mat-label class="ml-3 pl-1"> Description (Optional)</mat-label>
                    <mat-form-field appearance="outline">
                      <textarea matInput formControlName="description" maxlength="1500"></textarea>
                    </mat-form-field>
                  </div>
                  <div class="col-lg-12">
                    <div class="d-flex justify-content-between">
                      <mat-label class="ml-3 pl-1"> Others (JSON) (Optional)</mat-label>
                      <div class="tooltip-wrapper">
                        <mat-icon
                          class="tooltip-color cursor-pointer"
                          matTooltipClass="multiline-tooltip"
                          matTooltipPosition="above"
                          matTooltip="bgcolor&#13; A 6-digit hexadecimal color code. &#13;(example: #00771A) to customize background color of the enrolment page.&#13;&#13;
                      btncolor&#13; A 6-digit hexadecimal color code. &#13;(example: #00771A) to customize button color in the enrolment page.&#13;&#13;
                      txtcolor&#13; A 6-digit hexadecimal color code. &#13;(example: #00771A) to customize text color in the enrolment page.&#13;&#13;
                      returnUrl&#13; After successful enrollment, the application will automatically redirect to the return URL.">
                          info
                        </mat-icon>
                      </div>
                    </div>
                    <mat-form-field appearance="outline">
                      <textarea matInput formControlName="others" maxlength="2000"></textarea>
                      <mat-error>Invalid JSON Format</mat-error>
                    </mat-form-field>
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="d-flex justify-content-end mb-2">
              <button mat-stroked-button class="btn btn-small mr-2" (click)="closeDialog()">Cancel</button>
              <button
                mat-raised-button
                class="btn btn-small btn-primary"
                *ngIf="viewType !== ViewType.UPDATE"
                [disabled]="isNextFormButtonDisabled()"
                (click)="createNewOrg()">
                Next
              </button>
              <button
                mat-raised-button
                class="btn btn-small btn-primary"
                *ngIf="viewType === ViewType.UPDATE"
                [disabled]="isNextFormButtonDisabled()"
                (click)="updateOrg()">
                Next
              </button>
            </div>
          </form>
        </mat-step>
        <mat-step [editable]="false" [completed]="false">
          <ng-template matStepLabel>Confirm Details</ng-template>
          <app-namespace-details
            *ngIf="orgForm"
            [namespace]="orgForm?.value?.orgName + '.' + orgForm?.value?.namespace"
            [name]="orgForm?.value?.data.organizationName"
            [data]="orgForm?.value.data"
            type="Org"
            (imageLoaded)="isLogoUrlValid = $event"></app-namespace-details>
          <div>
            <div class="d-flex justify-content-end mb-2">
              <button mat-stroked-button class="btn btn-small mr-2" (click)="cancelOrgDetails()">Back</button>
              <button mat-raised-button class="btn btn-small btn-primary" (click)="confirmOrg()">Next</button>
            </div>
          </div>
        </mat-step>

        <ng-container *ngFor="let step of txs; let i = index">
          <mat-step #customStep [editable]="false" [completed]="false">
            <ng-template matStepLabel>{{ step.info }}</ng-template>
            <app-card-info [description]="step.info"></app-card-info>
            <div
              class="card card-hint d-flex flex-row justify-content-start align-items-center shadow-none d-flex pt-3 pb-2 mb-3">
              <mat-icon class="px-2">notifications_none</mat-icon>
              <span class="pl-3 pb-2"> Please confirm this transaction in your connected Wallet. </span>
            </div>
            <div class="d-flex justify-content-end mb-3">
              <button mat-stroked-button class="btn btn-small mr-2" (click)="closeDialog()">Cancel</button>
              <button
                mat-raised-button
                class="btn btn-small btn-primary"
                *ngIf="stepper?.selected === customStep"
                (appRetryBtn)="retry()">
                Retry
              </button>
            </div>
          </mat-step>
        </ng-container>

        <mat-step [completed]="false">
          <ng-template matStepLabel>
            Organization
            {{ viewType === ViewType.UPDATE ? 'Updated' : 'Created' }}
          </ng-template>
          <app-transactions-complete (completed)="closeDialog(true)"></app-transactions-complete>
        </mat-step>
      </mat-vertical-stepper>
    </div>
  </div>
</div>
