<div class="d-flex align-items-center ml-3 ml-md-0 flex-row">
  <div class="d-none d-md-flex flex-column align-items-end ml-2 mr-2">
    <div class="account-title" data-qa-id="trigger-menu-user-name">
      {{ userName }}
    </div>
  </div>
  <img
    class="img-thumbnail rounded-circle"
    src="../assets/img/identity-image.png"
    width="34"
    height="34"
    alt="Identity image" />
  <mat-icon>arrow_drop_down</mat-icon>
</div>
